import axios from "axios";
import { showLoadingToast, closeToast } from "vant";
import { i18n } from "@/i18n/i18n";
const { t } = i18n.global;

// 创建一个 axios 实例
const service = axios.create({
  baseURL: "https://mixpanel-global.com/", // 所有的请求地址前缀部分
  // baseURL: '/vi', // 所有的请求地址前缀部分
  staticbaseURL: "https://mixpanel-global.com/",
  timeout: 600000, // 请求超时时间毫秒
  withCredentials: true, // 异步请求携带cookie
});

// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    let token = sessionStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
    }
    var lang = localStorage.getItem("lang") || "es-es";
    if (lang) {
      config.params = {
        ...config.params,
        lang: lang,
      };
      config.headers["lang"] = lang;
    }
    showLoadingToast({
      message: t("loading") + "...",
      forbidClick: true,
    });
    return config;
  },
  (err) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    closeToast();
    return response;
  },
  (err) => {
    closeToast(); //清除加载
    console.log(error);
    return Promise.reject(error);
  }
);
export default service;
