module.exports = {
  banner1_text:
    "Life is too short to spend time with people who suck the happiness out of you. If someone wants you in their life, they’ll make room for you.",
  leistungsumfang: "Leistungsumfang",
  leistungsumfang_text:
    "Currently serving 100.000+ custormers and receiving good reviews",
  leistungsumfang_free: "FREE TRANSFER",
  home: "Home",
  about: "About",
  order: "Order",
  record: "Order record",
  user: "Mine",
  email: "email",
  about_text1: "Quality goods",
  about_text2: "More products",
  order_title: "Todays order",
  zongzichan: "Total assets",
  jinridan: "Number of orders today",
  tiyanjin: "Experience Fund",
  jinrishou: "Todays income",
  jintuandui: "Team commission today",
  dianjigou: "Start task",
  adianjigou: "Submit",
  order_illustrate:
    "The platform is not responsible if money is incorrectly transferred to your bank account.The minimum withdrawal amount is 50,000 won.",
  order_record: "Order record",
  all: "All",
  undone: "Undone",
  completed: "Completed",
  score: "Score",
  benutzer: "User",
  chongzhi: "Recharge",
  tixian: "Withdraw",
  details: "Account details",
  password: "Password management",
  password_jy: "Transfer Password",
  notify: "System notification",
  kefu: "Customer service",
  bank_card: "Bank card",
  invite: "Invite friends",
  team: "Team report",
  language: "Choose a language",
  member: "Member upgrade",
  balance: "Account Balance",
  quit: "Sign out",
  invite_text1: "Invite friends to earn cash",
  invite_code: "Invitation code",
  invite_btn: "Invitation link: click to copy",
  copy_s: "Copied successfully",
  copy_b: "Copy failed",
  grade: "Membership level",
  my_money: "My account balance",
  open_member: "Join membership",
  withdraw_num: "Number of withdrawals",
  day: "Sky",
  withdraw_quota: "Withdrawal limit",
  order_num: "Order quantity",
  profit_scale: "Commission rate",
  member_time: "Membership is valid forever",
  confirm_pay: "confirm payment",
  order_sub: "Submit Order",
  user_info: "User Info",
  avatar: "Avatar",
  username: "Username",
  set_up: "Set up now",
  revise_name: "Modify name",
  username_hint: "Please enter your username",
  user_account: "User account",
  add_money: "Top up",
  add_money_num: "Recharge amount",
  add_money_hint1:
    "1. The payment amount must be consistent with the order amount, otherwise it will not arrive automatically",
  add_money_hint2:
    "2. If the recharge and withdrawal are not received, please consult your superior or customer service to solve other problems.",
  add_money_hint3: "Please contact customer service for fees",
  add_money_record: "Recharge record",
  withdraw_record: "Withdrawals record",
  withdraw_money: "Withdrawal Amount",
  can_withdraw_money: "Amount of cash available",
  order_number: "SN",
  money: "Amount",
  bank: "BANK",
  bank1: "banks",
  transaction_flow: "Transaction serial number",
  already_paid: "I have paid",
  upload_pay_img: "Upload payment screenshot",
  order_details: "Account details",
  old_password: "Old Password",
  new_password: "New Password",
  repeat_password: "Repeat password",
  enter: "Please enter",
  password_hint:
    "Please remember your password. If you forget your password, please contact customer service.",
  submit: "Submit",
  bankCard: "Bind bank card",
  bank: "Account opening bank",
  bank_card_num: "Bank card number",
  bank_card_name: "Cardholders Name",
  team: "Team reports",
  today: "Today",
  yesterday: "Yesterday",
  week: "This week",
  scale: "Proportion",
  team_people: "Team size",
  team_order_scale: "Team order commission",
  open_bank_name: "Account Name",
  phone: "phone",
  user_password: "Password",
  login: "Log in",
  register: "Register",
  password_qr: "Confirm Password",
  pwd_no_same: "Passwords are inconsistent",
  loading: "Loading",
  freeze: "Freeze",
  pending: "Pending",
  order_hao: "Order number",
  order_time: "Order grabbing time",
  price: "Unit price",
  order_total_price: "Order total",
  scale: "Commission",
  level: "Membership level",
  level_up: "Member upgrade",
  pay_way: "Recharge method",
  money_min: "The amount is too small",
  pay_no: "Please select a recharge method",
  // 新加
  team_all: "All",
  team_yi: "Level 1",
  team_er: "Level 2",
  team_san: "Level 3",
  close_order: "Cancel order",
  shouru: "Income",
  zhichu: "Expenditure",
  welcome: "Welcome",
  // 新加2
  order_kong: "Order number cannot be empty",
  quer: "Confirm",
  quxiao: "Cancel",
  qianbaodi: "Wallet address",
  xingming: "Name",
  bank_chong: "Cannot be bound repeatedly",
  introduce: "Company Profile",
  platform: "Platform rules",
  tixianzhang: "Withdraw account",
  xuanze: "Please choose",
  xiayiye: "Click to load next page",
  // 新加3
  frequentoperation: "Do not operate frequently",
};
