module.exports = {
  banner1_text:
    "La vita è troppo breve per passare il tempo con persone che ti succhiano via la felicità. Se qualcuno vi vuole nella sua vita, vi farà spazio.",
  leistungsumfang: "Ambito dei servizi",
  leistungsumfang_text:
    "Attualmente serve oltre 100.000 clienti e riceve buone recensioni.",
  leistungsumfang_free: "TRASFERIMENTO GRATUITO",
  home: "Casa",
  about: "Circa",
  order: "Ordine",
  record: "Registrazione dell'ordine",
  user: "La mia",
  email: "email",
  about_text1: "Prodotti di qualità",
  about_text2: "Altri prodotti",
  order_title: "Ordine di oggi",
  zongzichan: "Totale attività",
  jinridan: "Numero di ordini oggi",
  tiyanjin: "Fondo per l'esperienza",
  jinrishou: "Il reddito di oggi",
  jintuandui: "Commissione di squadra oggi",
  dianjigou: "Inizio attività",
  adianjigou: "Invia",
  order_illustrate:
    "La piattaforma non è responsabile se il denaro viene trasferito in modo errato sul vostro conto bancario. L'importo minimo di prelievo è di 50.000 won.",
  order_record: "Registrazione dell'ordine",
  all: "Tutti",
  undone: "Disfatto",
  completed: "Completato",
  score: "Punteggio",
  benutzer: "Utente",
  chongzhi: "Ricarica",
  tixian: "Ritiro",
  details: "Dettagli del conto",
  password: "Gestione delle password",
  password_jy: "Password di trasferimento",
  notify: "Notifica del sistema",
  kefu: "Servizio clienti",
  bank_card: "Carta bancaria",
  invite: "Invitare gli amici",
  team: "Rapporto di squadra",
  language: "Scegliere una lingua",
  member: "Aggiornamento dei membri",
  balance: "Saldo del conto",
  quit: "Esci",
  invite_text1: "Invitare gli amici per guadagnare denaro",
  invite_code: "Codice invito",
  invite_btn: "Link dell'invito: fare clic per copiare",
  copy_s: "Copiato con successo",
  copy_b: "Copia fallita",
  grade: "Livello di appartenenza",
  my_money: "Il saldo del mio conto",
  open_member: "Aderire all'associazione",
  withdraw_num: "Numero di prelievi",
  day: "Il cielo",
  withdraw_quota: "Limite di prelievo",
  order_num: "Quantità dell'ordine",
  profit_scale: "Tasso di commissione",
  member_time: "L'iscrizione è valida per sempre",
  confirm_pay: "confermare il pagamento",
  order_sub: "Invia l'ordine",
  user_info: "Info utente",
  avatar: "Avatar",
  username: "Nome utente",
  set_up: "Configura ora",
  revise_name: "Modificare il nome",
  username_hint: "Inserisci il tuo nome utente",
  user_account: "Account utente",
  add_money: "Ricarica",
  add_money_num: "Importo della ricarica",
  add_money_hint1:
    "1. L'importo del pagamento deve corrispondere all'importo dell'ordine, altrimenti non arriverà automaticamente.",
  add_money_hint2:
    "2. Se la ricarica e il prelievo non vengono ricevuti, consultare il proprio superiore o il servizio clienti per risolvere altri problemi.",
  add_money_hint3: "Contattare il servizio clienti per conoscere le tariffe",
  add_money_record: "Registro di ricarica",
  withdraw_record: "Registro dei ritiri",
  withdraw_money: "Importo del prelievo",
  can_withdraw_money: "Importo della liquidità disponibile",
  order_number: "SN",
  money: "Importo",
  bank: "BANCA",
  bank1: "banche",
  transaction_flow: "Numero di serie della transazione",
  already_paid: "Ho pagato",
  upload_pay_img: "Caricare la schermata di pagamento",
  order_details: "Dettagli del conto",
  old_password: "Vecchia password",
  new_password: "Nuova password",
  repeat_password: "Ripetere la password",
  enter: "Inserire",
  password_hint:
    "Ricordare la password. Se si dimentica la password, contattare il servizio clienti.",
  submit: "Invia",
  bankCard: "Legare la carta bancaria",
  bank: "Apertura del conto bancario",
  bank_card_num: "Numero di carta bancaria",
  bank_card_name: "Nome del titolare della carta",
  team: "Rapporti di squadra",
  today: "Oggi",
  yesterday: "Ieri",
  week: "Questa settimana",
  scale: "Proporzione",
  team_people: "Dimensione della squadra",
  team_order_scale: "Commissione d'ordine del team",
  open_bank_name: "Nome del conto",
  phone: "telefono",
  user_password: "parola d'ordine",
  login: "Accedi",
  register: "Registro",
  password_qr: "Conferma la password",
  pwd_no_same: "Le password sono incoerenti",
  loading: "Caricamento",
  freeze: "Congelare",
  pending: "In attesa",
  order_hao: "Numero d'ordine",
  order_time: "Tempo di presa dell'ordine",
  price: "Prezzo unitario",
  order_total_price: "Totale dell'ordine",
  scale: "Commissione",
  level: "Livello di appartenenza",
  level_up: "Aggiornamento dei membri",
  pay_way: "Metodo di ricarica",
  money_min: "Metodo di ricarica",
  pay_no: "Selezionare un metodo di ricarica",
  // 新加
  team_all: "Tutti",
  team_yi: "Livello 1",
  team_er: "Livello 2",
  team_san: "Livello 2",
  close_order: "Annullamento dell'ordine",
  shouru: "Reddito",
  zhichu: "Spese",
  welcome: "Benvenuti",
  // 新加2
  order_kong: "Il numero d'ordine non può essere vuoto",
  quer: "Confermare",
  quxiao: "Annullamento",
  qianbaodi: "Indirizzo del portafoglio",
  xingming: "Nome",
  bank_chong: "Non può essere vincolato ripetutamente",
  introduce: "Profilo aziendale",
  platform: "Regole della piattaforma",
  tixianzhang: "Prelievo dal conto",
  xuanze: "Scegliere",
  xiayiye: "Fare clic per caricare la pagina successiva",
  // 新加3
  frequentoperation: "Non operare frequentemente",
};
