import zh from "./lang/zh";
import de from "./lang/de";
import jp from "./lang/jp";
import es from "./lang/es";
import lt from "./lang/lt";
// // 引入英文语言包
import zhCN from "vant/es/locale/lang/zh-CN";
import deDE from "vant/lib/locale/lang/de-DE";
import jaJP from "vant/lib/locale/lang/ja-JP";
import eses from "vant/lib/locale/lang/de-DE";
import ltLT from "vant/lib/locale/lang/it-IT";
export default {
  "hy-hy": { ...zh, ...zhCN },
  "lt-lt": { ...lt, ...ltLT },
  "de-de": { ...de, ...deDE },
  "jp-jp": { ...jp, ...jaJP },
  "es-es": { ...es, ...eses },
};
